import React from 'react'
import useDataLayerFooter from '../../hooks/useDataLayer/dataLayerFooter'
import Icon from '../Icon'
import Image from '../Image'
import OpenModal from '../OpenModal'

import * as S from './styles'

const FooterComponent: React.FC = () => {
  const [ sendDatalayerFooterEvent ] = useDataLayerFooter()

  return (
    <S.Footer className='d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <Image name='LogoInterAssetNewBranding' alt='Logo Inter Asset Footer' className='logo-inter-asset-footer' />
              <div className='d-flex icons-div'>
                <a
                  href='http://linkedin.com.br/company/interasset'
                  title='Síga-nos no Linkedin' target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_name: 'Linkedin',
                      element_action: 'click button',
                      redirect_url: 'http://linkedin.com.br/company/interasset',
                    })
                  }}
                >
                  <Icon icon='social/new-linkedin' directory='v2' height={24} width={24} color='white' className='d-md-none' />
                  <Icon icon='social/new-linkedin' directory='v2' height={32} width={32} color='white' className='d-none d-md-block' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5'>
            <div className='d-md-flex flex-wrap'>
              <div>
                <p className='text-white fs-16 fs-md-14 lh-20 fw-100 mb-3'>
                  <span className='d-block'>Avenida Barbacena, 1219, 21º andar</span>
                  <span className='d-block'>Belo Horizonte, MG - Brasil</span>
                </p>
                <p className='text-white fs-16 fs-md-14 lh-20 fw-100 mb-0'>
                  <span className='d-block'>Fale com o RI: <a href='mailto:ri@interasset.com.br' className='fw-700 text-white'>ri@interasset.com.br</a></span>
                </p>
              </div>
              <S.GrayBar className='my-4 my-md-0 mx-md-4' />
              <div>
                <div
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_name: 'Política de Privacidade do Inter',
                      element_action: 'click button',
                    })
                  }}
                >
                  <OpenModal className='privacy-policy' />
                </div>
                <a
                  href='https://canaldedenuncia.bancointer.com.br/'
                  className='fw-700 fs-14 lh-20 fs-md-16 lh-md-20 border-0 text-orange-medium bg-transparent px-0 d-block'
                  title='Canal de Ética'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_name: 'Canal de Ética',
                      element_action: 'click button',
                      redirect_url: 'https://canaldedenuncia.bancointer.com.br/',
                    })
                  }}
                >
                  Canal de Ética
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Footer>
  )
}

export default FooterComponent
